export enum ChargeType {
    stripe = "StripeCharge",
    checkout = "CheckoutCharge",
    paypal = "PaypalCharge",
    stripeUntracked = "UntrackedStripeCharge",
    balanceAdjustment = "BalanceAdjustment",
    inStore = "InStoreCharge",
    thirdParty = "ThirdPartyCharge",
    square = "SquareCharge",
    ocmp = "OCMPCharge",
    zage = "ZageCharge",
    giftCards = "giftCards"
}

export enum ThirdPartySource {
    ALLSET = "ALLSET",
    BEYONDMENU = "BEYONDMENU",
    CAVIAR = "CAVIAR",
    CHOWBUS = "CHOWBUS",
    CHOWNOW = "CHOWNOW",
    CURBSIDENINJA = "CURBSIDENINJA",
    DELIVERECT = "DELIVERECT",
    DELIVERYCOM = "DELIVERYCOM",
    DOORDASH = "DOORDASH",
    EASI = "EASI",
    EZCATER = "EZCATER",
    FANTUAN = "FANTUAN",
    GRUBHUB = "GRUBHUB",
    HUNGRYPANDA = "HUNGRYPANDA",
    MEALO = "MEALO",
    OTHER = "OTHER",
    POSTMATES = "POSTMATES",
    RICEPO = "RICEPO",
    RITUAL = "RITUAL",
    SNACKPASS = "SNACKPASS",
    SOCIAVORE = "SOCIAVORE",
    TOPSERVE = "TOPSERVE",
    UBEREATS = "UBEREATS",
    WAITRR = "WAITRR",
    WEEE = "WEEE",
    ZOMATO = "ZOMATO"
}

export const DEFAULT_THIRD_PARTY_SOURCES = [
    ThirdPartySource.UBEREATS,
    ThirdPartySource.GRUBHUB,
    ThirdPartySource.DOORDASH,
    ThirdPartySource.POSTMATES,
    ThirdPartySource.OTHER
];

export const READABLE_THIRD_PARTY_MAPPING: Record<ThirdPartySource, string> = {
    [ThirdPartySource.ALLSET]: "Allset",
    [ThirdPartySource.BEYONDMENU]: "BeyondMenu",
    [ThirdPartySource.CAVIAR]: "Caviar",
    [ThirdPartySource.CHOWBUS]: "Chowbus",
    [ThirdPartySource.CHOWNOW]: "ChowNow",
    [ThirdPartySource.CURBSIDENINJA]: "CurbsideNinja",
    [ThirdPartySource.DELIVERECT]: "Deliverect",
    [ThirdPartySource.DELIVERYCOM]: "Delivery.com",
    [ThirdPartySource.DOORDASH]: "Doordash",
    /** EASI was acquired by HungryPanda and is now known by that name. */
    [ThirdPartySource.EASI]: "HungryPanda",
    [ThirdPartySource.EZCATER]: "ezCater",
    [ThirdPartySource.FANTUAN]: "Fantuan",
    [ThirdPartySource.GRUBHUB]: "Grubhub",
    [ThirdPartySource.HUNGRYPANDA]: "HungryPanda",
    [ThirdPartySource.MEALO]: "Mealo",
    [ThirdPartySource.OTHER]: "Other",
    [ThirdPartySource.POSTMATES]: "Postmates",
    [ThirdPartySource.RICEPO]: "RICEPO",
    [ThirdPartySource.RITUAL]: "Ritual",
    [ThirdPartySource.SNACKPASS]: "Snackpass",
    [ThirdPartySource.SOCIAVORE]: "Sociavore",
    [ThirdPartySource.TOPSERVE]: "TopServe",
    [ThirdPartySource.UBEREATS]: "UberEats",
    [ThirdPartySource.WAITRR]: "Waitrr",
    [ThirdPartySource.WEEE]: "Weee!",
    [ThirdPartySource.ZOMATO]: "Zomato"
};

export enum ThirdPartySourceType {
    MANUAL = "MANUAL",
    API = "API"
}

export enum CardBrand {
    VISA = "visa",
    MASTERCARD = "mastercard",
    DISCOVER = "discover",
    AMEX = "amex",
    DINERS = "diners",
    JCB = "jcb",
    UNIONPAY = "unionpay",
    UNKNOWN = "unknown"
}

export interface ICharge {
    _id: string;
    _amount: number;
    _processingFee: number | null;
    chargeId: string | null;
    chargeType: ChargeType;
    createdAt: Date;
    updatedAt?: Date;
}

export interface IStripeCharge extends ICharge {
    chargeType: ChargeType.stripe;
    paymentIntentId?: string;
    _fixedFee?: number;
    _variableRate?: number;
    _last4?: string;
    _brand?: CardBrand;
}

export interface ICheckoutCharge extends ICharge {
    chargeType: ChargeType.checkout;
    _fixedFee: number | null;
    _variableFee: number | null;
}

export interface IPaypalCharge extends ICharge {
    chargeType: ChargeType.paypal;
}

export interface IUntrackedStripeCharge extends ICharge {
    chargeId: null;
    chargeType: ChargeType.stripeUntracked;
}

export interface IBalanceAdjustment extends ICharge {
    chargeId: null;
    chargeType: ChargeType.balanceAdjustment;
    description?: string;
}

export interface IInStoreCharge extends ICharge {
    chargeId: null;
    chargeType: ChargeType.inStore;
    description?: string;
    type: IInStoreChargeType;
    hasPaidCash?: boolean;
    cashTotalCents?: number;
}

export enum IInStoreChargeType {
    cash = "CASH",
    other = "OTHER"
}

export interface IThirdPartyCharge extends ICharge {
    chargeId: null;
    chargeType: ChargeType.thirdParty;
    description?: string;
    source: string;
    sourceType: ThirdPartySourceType;
}

export interface ISquareCharge extends ICharge {
    chargeType: ChargeType.square;
    clientId: string;
    tenderId: string;
    _fixedFee: number | null;
    _percentFee: number | null;
}

export type OCMPCard = {
    pan: string;
};

export interface IOCMPCharge extends ICharge {
    ocmpCard: OCMPCard;
    chargeType: ChargeType.ocmp;
}

export interface IZageCharge extends ICharge {
    userId: string;
    amountChargedCents: number;
    chargeType: ChargeType.zage;
}
